import React, { useEffect, useState } from "react"; // Ensure useCallback is imported
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";
import { GetallProduct, deleteproduct, updateproduct, updateproductimages } from "backendServices/ApiCalls";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import PhotoIcon from '@mui/icons-material/Photo';
import { useDropzone } from "react-dropzone";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  price: yup.number().required("Price is required"),
  msrprice: yup.number().required("MSR Price is required"),
  description: yup.string().required("Description is required"),
});

function Products() {
  const [manageallproduct, setManageAllProduct] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [files, setFiles] = useState([]);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    position: "relative",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (files.length + acceptedFiles.length > 5) {
        setalertData({
          show: true,
          message: "You can only select a maximum of 5 images.",
          variant: "error",
        });
        return;
      }
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={index}>
      <div style={thumbInner}>
        <CancelIcon
          className="remove-icon"
          onClick={() => handleRemoveImage(index)}
        />
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  ));

  // Add appropriate styling to the remove button
  const handleRemoveImage = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file, index) => index !== indexToRemove)
    );
  };


  const getdata = () => {
    setLoading(true);
    GetallProduct(
      (response) => {
        setManageAllProduct(response?.data?.data);
        if (response?.data?.status === "success") {
          setLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    getdata();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Seller Name",
      width: 150,
      editable: true,
    },
    {
      field: "title",
      headerName: "Product Title",
      width: 150,
      editable: true,
    },
    {
      field: "msrprice",
      headerName: "MSR Price",
      width: 150,
      renderCell: (params) => `$${params.value}`,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      renderCell: (params) => `$${params.value}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "picture",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <EditIcon
              color="warning"
              sx={{ cursor: "pointer" }}
              onClick={() => handleData(params?.row?.id)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteIcon
              color="error"
              sx={{ cursor: "pointer" }}
              onClick={() => handleOpen(params?.row?.id)}
            />
          </Tooltip>
          <Tooltip title="Update Images">
            <PhotoIcon
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => handleFunction(params?.row?.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const handleData = (id) => {
    const rowToEdit = manageallproduct.find((row) => row.id === id);
    setRowData(rowToEdit);
    handleModalOpen();
  };

  const handleFunction = (id) => {
    const rowToEdit = manageallproduct.find((row) => row.id === id);
    setRowData(rowToEdit);
    handleImageModalOpen();
  };

  const handleOpen = (id) => {
    const rowToEdit = manageallproduct.find((row) => row.id === id);
    setRowData(rowToEdit);
    let params = {
      id,
    };
    deleteproduct(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          getdata();
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    let params = {
      id: rowData?.id,
      title: data?.title,
      price: data?.price,
      msrprice: data?.msrprice,
      description: data?.description
    }
    updateproduct(
      params,
      (response) => {
        console.log(response);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          getdata();
          handleModalClose();
          setSubmitting(false);
          resetForm(); // Reset the entire form
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handleImageSubmit = () => {

    if (files.length === 0) {
      setalertData({
        show: true,
        message: "Please upload at least one image.",
        variant: "error",
      });
      setLoading(false);
      return; // Prevent form submission if less than 4 images are uploaded
    }

    if (files.length > 5) {
      setalertData({
        show: true,
        message: "You can upload maximum 5 images!",
        variant: "error",
      });
      setLoading(false);
      return; // Prevent form submission if less than 4 images are uploaded
    }

    const formDataToSend = new FormData();
    files.forEach((file, index) => {
      // Generate a unique filename using the current timestamp
      const imageName = `car-img-${Date.now()}-${index}.${file.name
        .split(".")
        .pop()}`;
      formDataToSend.append("images", file, imageName);
    });

    formDataToSend.append("pid", rowData?.id);
    console.log('formDataToSend', formDataToSend)
    updateproductimages(
      formDataToSend,
      (response) => {
        console.log(response);
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          handleImageModalClose();
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          handleImageModalClose(); // Reset the form
        } else {
          setalertData({
            show: true,
            message: "Something went wrong, please try again later",
            variant: "error",
          });
          handleImageModalClose();
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };


  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleImageModalOpen = () => setOpenImage(true);
  const handleImageModalClose = () => setOpenImage(false);

  if (loading) {
    return (
      <Div
        sx={{
          mt: "20%",
          ml: "45%",
          mb: "20%",
        }}
      >
        <CircularProgress />
      </Div>
    );
  }

  return (
    <Grid container fullWidth p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Products"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <Box sx={{ height: 500, width: 1 }} textAlign="right">
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={manageallproduct}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
        </JumboDemoCard>
      </Grid>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            validateOnChange={true}
            initialValues={{
              title: rowData?.title || "",
              price: rowData?.price || "",
              msrprice: rowData?.msrprice || "",
              description: rowData?.description || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleSubmit(data, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting, handleChange, values }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="title"
                    label="Title"
                    type="text"
                  />
                </Div>

                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="msrprice"
                    label="MSR Price"
                    type="number"
                  />
                </Div>

                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="price"
                    label="Price"
                    type="number"
                  />
                </Div>

                <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    name="description"
                    minRows={3}
                    placeholder="Description"
                    style={{ width: "100%" }}
                    value={values.description.replace(/\\\\\\'/g, "'")}
                    onChange={handleChange}
                  />
                </Div>

                <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Div>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Modal
        open={openImage}
        onClose={handleImageModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h2" gutterBottom>
            Update Images
          </Typography>
          <div className="mb-3 text-center">
            <div
              style={{ border: "2px dashed #BBBBBB" }}
              {...getRootProps({ className: "dropzone p-4" })}
            >
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </div>
          <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              sx={{ mb: 3 }}
              onClick={handleImageSubmit}
            >
              Submit
            </Button>
          </Div>
        </Box>
      </Modal>
    </Grid>
  );
}

export default Products;

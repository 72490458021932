import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import { dashboardAPI } from "backendServices/ApiCalls";
import { Group, VerifiedUser } from "@mui/icons-material";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import StoreIcon from "@mui/icons-material/Store";
import Div from "@jumbo/shared/Div";
import PortfolioBalance from "app/shared/metrics/PortfolioBalance/PortfolioBalance";

const Crypto = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loader, setLoader] = useState(true);

  const dashboardApiFunction = () => {
    dashboardAPI(
      (response) => {
        setLoader(false);
        setDashboardData(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    dashboardApiFunction();
  }, []);

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Grid container spacing={3.75}>
    <Grid item xs={12} sm={6} lg={4}>
        <ObjectCountRevenue
          value={dashboardData?.total_users}
          title="Total Users"
          color="success.main"
          icon={<Group fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ObjectCountRevenue
          value={dashboardData?.active_users}
          title="Total Buyers"
          color="success.main"
          icon={<VerifiedUser fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ObjectCountRevenue
          value={dashboardData?.stockist_users}
          title="Total Sellers"
          color="primary.main"
          icon={<StoreIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ObjectCountRevenue
          value={'$100'}
          title="Company Revenue"
          color="success.main"
          icon={<Group fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ObjectCountRevenue
          value={'$50'}
          title="Sellers Revenue"
          color="success.main"
          icon={<VerifiedUser fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <ObjectCountRevenue
          value={dashboardData?.stockist_users}
          title="Sold Items"
          color="primary.main"
          icon={<StoreIcon fontSize="large" />}
          vertical={true}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={6}>
        <PortfolioBalance dashboardApiData={dashboardData} />
      </Grid>

      <Grid item xs={12} sm={12} lg={6}>
        <Grid container spacing={3.75}>
          <Grid item xs={12} sm={6} lg={6}>
            <ObjectCountRevenue
              value={dashboardData?.inactive_users || 0}
              title="Pending Sellers"
              color="primary.main"
              icon={<Group fontSize="large" />}
              vertical={true}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={6}>
            <ObjectCountRevenue
              value={dashboardData?.pending_achievers}
              title="Rank Achievers"
              color="primary.main"
              icon={<MilitaryTechIcon fontSize="medium" />}
              vertical={true}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} lg={6}>
            <ObjectCountRevenue
              value={dashboardData?.pending_deposit}
              title="Approved Sellers"
              color="success.main"
              icon={<VerifiedUser fontSize="medium" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <ObjectCountRevenue
              value={dashboardData?.approved_deposit}
              title="Rejected Sellers"
              color="error.main"
              icon={<UnpublishedIcon fontSize="medium" />}
              vertical={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <ObjectCountRevenue
              value={`$${dashboardData?.total_earning}`}
              title="Your Revenue"
              color="success.main"
              icon={<VerifiedUser fontSize="medium" />}
              vertical={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Crypto;

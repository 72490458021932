import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import Div from "@jumbo/shared/Div";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CancelIcon from "@mui/icons-material/Cancel";
import { getproduct, postRequest } from "backendServices/ApiCalls";
import ReactImageGallery from "react-image-gallery";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

const Manageproduct = () => {
  const [manageallproduct, setManageAllProduct] = useState([]);
  const [imageurl, setImageUrl] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [imagesData, setImagesData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const modalOpen = () => setOpen(true);
  const modalClose = () => setOpen(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [productId, setProductId] = useState("");
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const GetallProduct = () => {
    getproduct(
      (response) => {
        setManageAllProduct(response?.data?.data);
        setImageUrl(response?.data?.imageURL);
        if (response?.data?.data?.status === "success") {
          console.log("response get Successfully");
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetallProduct();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Seller Name",
      width: 150,
      editable: true,
    },
    {
      field: "title",
      headerName: "Product Title",
      width: 150,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      renderCell: (params) => `$${params.value}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      // renderCell: (params) => `$${params.value}`,
    },
    {
      field: "picture",
      headerName: "Product Detail",
      width: 150,
      renderCell: (params) => (
        <Button 
        variant="contained"
        onClick={() => handleOpen(params?.row?.id)}
      >
        View
      </Button>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      renderCell: (params) => (
        <>
          <Tooltip title="Approve" placement="top">
            <IconButton
              onClick={() => handleActionClick(params.row.id, "approved")}
              sx={{ color: "success.main" }}
            >
              <AddTaskIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject" placement="top">
            <IconButton
              onClick={() => handleActionClick(params.row.id, "rejected")}
              sx={{ color: "error.main" }}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];
  const handleOpen = (id) => {
    const rowToEdit = manageallproduct.find((row) => row.id === id);
    setRowData(rowToEdit);
    const parsedData = JSON.parse(rowToEdit?.images)
    const transformedData =parsedData?.imageNames.map(imageName => ({
      original: imageurl+imageName,
      thumbnail: imageurl+imageName
    }));
    setImagesData(transformedData)
    modalOpen();
  };


  const handleActionClick = (id, action) => {
    if (action === "approved") {
      setOpenApproveDialog(true);
      setProductId(id);
    } else if (action === "rejected") {
      setOpenDialog(true);
      setProductId(id);
    }
  };

  const handleApprove = (id) => {
    return new Promise((resolve) => {
      let params = {
        status: "approved",
        pid: productId,
      };
			console.log('params', params)
      postRequest(
        "/productaction",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setOpenApproveDialog(false);
						setalertData({
							show: true,
							message: response?.data?.message,
							variant: "success",
						});
            GetallProduct();
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve();
        }
      );
    });
  };

  const handleReject = (id, amount, reason) => {
    return new Promise((resolve) => {
      let params = {
        status: "rejected",
        pid: productId,
      };

      postRequest(
        "/productaction",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setOpenDialog(false);
						setalertData({
							show: true,
							message: response?.data?.message,
							variant: "success",
						});
            GetallProduct();
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve();
        }
      );
    });
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Grid container fullWidth p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Pending Products"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 500, width: 1 }} textAlign="right">
            {/* <Link to="/add-product">
                <Button variant="contained" sx={{ marginTop: '-50px'}} href="!#">
                        Add Product
                </Button>
          </Link> */}
            {({ isSubmitting }) => (
              <Div
                sx={{
                  mt: "20%",
                  ml: "45%",
                  mb: "20%",
                }}
              >
                <CircularProgress />
              </Div>
            )}
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={manageallproduct}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          {/* Update Product Modal */}
        </JumboDemoCard>
      </Grid>
      <Modal
        open={open}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
        <Grid container>
              <Grid item xs={12} md={6}>
                <ReactImageGallery
                  showBullets={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={imagesData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Div>
                  <Typography variant="h2" sx={{ ml: 2, mt: 3 }}>
                    {rowData?.title}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Seller Username: {rowData?.username}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Full Name: {rowData?.firstname + " " + rowData?.lastname}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Email: {rowData?.email}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="h1" color="primary" sx={{ mt: 3, ml: 2 }}>
                    ${rowData?.price}{" "}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Status: {rowData?.status}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="h5" color="initial" sx={{ mt: 3, ml: 2 }}>Detail:</Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>{rowData?.description}</Typography>
                </Div>
              </Grid>
            </Grid>
        </Div>
      </Modal>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reject this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleCloseDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleReject}
            color="error"
            autoFocus
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openApproveDialog} onClose={handleCloseApproveDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve this product
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleCloseApproveDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleApprove}
            color="success"
            autoFocus
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Manageproduct;

import { AiOutlineHeart } from "react-icons/ai";
import { BiShoppingBag } from "react-icons/bi";
import ReactImageGallery from "react-image-gallery";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import "./style.css";
import { Grid, Typography } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Div from "@jumbo/shared/Div";
import { useLocation } from "react-router-dom";
import { postRequest } from "backendServices/ApiCalls";
import { useEffect, useState } from "react";

function ProductDetail() {
  const [userData, setUserData] = useState([])
  const location = useLocation();
  const rowData = location.state?.rowData;
  const imgUrl = location.state?.imageUrl;
  const imagesData = JSON.parse(rowData.images);
  const productDetailItem = {
    images: [
      {
        original: `${imgUrl}${imagesData.imageNames[0]}`,
        thumbnail:
          `${imgUrl}${imagesData.imageNames[0]}`,
      },
      {
        original: `${imgUrl}${imagesData.imageNames[1]}`,
        thumbnail:
          `${imgUrl}${imagesData.imageNames[1]}`,
      },
      {
        original: `${imgUrl}${imagesData.imageNames[2]}`,
        thumbnail:
          `${imgUrl}${imagesData.imageNames[2]}`,
      },
      {
        original: `${imgUrl}${imagesData.imageNames[3]}`,
        thumbnail:
          `${imgUrl}${imagesData.imageNames[3]}`,
      },
      {
        original: `${imgUrl}${imagesData.imageNames[4]}`,
        thumbnail:
          `${imgUrl}${imagesData.imageNames[4]}`,
      },
    ],
    title: "BIG ITALIAN SOFA",
    reviews: "150",
    availability: true,
    brand: "apex",
    category: "Sofa",
    sku: "BE45VGTRK",
    price: 450,
    previousPrice: 599,
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem exercitationem voluptate sint eius ea assumenda provident eos repellendus qui neque! Velit ratione illo maiores voluptates commodi eaque illum, laudantium non!",
    size: ["XS", "S", "M", "L", "XL"],
    color: ["gray", "violet", "red"],
  };
  const ReferralData = () => {
    let params = {
      userid: rowData?.userid,
    }
    postRequest('/getsingleuserdata', params, (response) => {
      if (response?.data?.status === "success") {
        console.log('response', response)
        setUserData(response?.data?.data)
      }
    }, (error) => {
      console.log(error?.response?.data);
    })
  }

  useEffect(() => {
    ReferralData();
  }, [])
  const plusMinuceButton =
    "flex h-8 w-8 cursor-pointer items-center justify-center border duration-100 hover:bg-neutral-100 focus:ring-2 focus:ring-gray-500 active:ring-2 active:ring-gray-500";
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <JumboDemoCard
            title={"Product Detail"}
            wrapperSx={{ backgroundColor: "background.paper" }}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <ReactImageGallery
                  showBullets={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={productDetailItem.images}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Div>
                  <Typography variant="h2" sx={{ ml: 2, mt: 3 }}>
                    {rowData?.title}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Seller Username: {rowData?.username}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Full Name: {userData[0]?.firstname + " " + userData[0]?.lastname}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Email: {userData[0]?.email}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="h1" color="primary" sx={{ mt: 3, ml: 2 }}>
                    ${rowData?.price}{" "}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    status: {rowData?.status}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="h5" color="initial" sx={{ mt: 3, ml: 2 }}>Detail:</Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>{rowData.description}</Typography>
                </Div>
              </Grid>
            </Grid>
          </JumboDemoCard>
        </Grid>
      </Grid>
    </>
  );
}

export default ProductDetail;

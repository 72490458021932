import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  getusers,
  postRequest,
  updatestockistsdata,
} from "backendServices/ApiCalls";
import { Link, useNavigate } from "react-router-dom";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  Modal,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";
import { Login } from "@mui/icons-material";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";


const validationSchema = yup.object({
  storename: yup.string().required("Store name is required"),
  mobile: yup.number().required("Mobile is required"),
  address: yup.string().required("Address is required"),
  // city: yup.string().required("City is required"),
  // country: yup.string().required("Country is required"),
});

const Active = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const [usersdata, setUsersData] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [rowData, setRowData] = useState(null);
  const [stockistData, setStockistData] = useState(null);
  const [citieslList, setCitiesList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [addStockistModalOpen, setAddStockistModalOpen] = useState(false);
  const [statusStockistModalOpen, setStatusStockistModalOpen] = useState(false);
  const [updateStockistModalOpen, setUpdateStockistModalOpen] = useState(false);
  const [dataType, setDataType] = useState("");
  const [countrieslList, setCountriesList] = useState([]);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const navigate = useNavigate();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  let params = {
    status: "approved",
    user_type: 'Seller'
  };


  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleOpen = (userid) => {
    const rowToEdit = usersdata.find((row) => row.userid === userid);
    // Construct the image URL using imageurl and the image filename from the row
    navigate("/products-by-user", {
      state: { userId: rowToEdit.userid, },
    })
  };

  const handleOpenStockist = (userId, type) => {
    const rowToEditStockist = rowsWithId.find((row) => row.userid === userId);
    setStockistData(rowToEditStockist);
    setAddStockistModalOpen(true);
    setDataType(type);
  };

  const handleOpenStockistUpdate = (userId, type) => {
    const rowToEditStockist = rowsWithId.find((row) => row.userid === userId);
    setStockistData(rowToEditStockist);
    setUpdateStockistModalOpen(true);
    setDataType(type);
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr#",
      width: 50,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist
    },
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "fullname",
      headerName: "Full Name",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "email",
      headerName: "email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "user_type",
      headerName: "User Type",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "sale",
      headerName: "Total Sale",
      width: 130,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `4`, 
    },
    {
      field: "product",
      headerName: "Products",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <Button 
          variant="contained"
          onClick={() => handleOpen(params?.row?.userid)}
        >
          View
        </Button>
    ),
    },
    {
      field: "createdat",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const handleCreateUserSession = (userId) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [userId]: true,
    }));
    setIsLoading(true);
    let params = {
      userId,
    };
    postRequest(
      "createusersession",
      params,
      (response) => {
        if (response?.data?.status === "success") {
          window.open(response?.data?.accessurl, "_blank");
          setIsLoading(false);
          setLoadingStates((prevState) => ({
            ...prevState,
            [userId]: false,
          }));
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const UsersData = () => {
    getusers(
      params,
      (response) => {
        console.log("userdata", response);
        if (response?.data?.status === "success") {
          setUsersData(response?.data?.userdata);
          setIsMainLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setIsMainLoading(false);
      }
    );
  };

  const fetchCountriesList = () => {
    postRequest("/getcountries", "", (response) => {
      console.log("countries data", response);
      setCountriesList(response?.data?.data);
    });
  };

  useEffect(() => {
    UsersData();
    fetchCountriesList();
  }, []);

  const handleClose = () => setModalOpen(false);
  const handleCloseAddStockist = () => setAddStockistModalOpen(false);
  const handleCloseStatusStockist = () => setStatusStockistModalOpen(false);
  const handleCloseUpdateStockist = () => setUpdateStockistModalOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 500,
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleUpdateAccount = () => {
    setBtnLoading(true);
    const params = {
      id: rowData?.userid,
    };
    postRequest(
      "/updateaccount",
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setModalOpen(false);
          setBtnLoading(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          UsersData();
          setModalOpen(false);
          setBtnLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const rows = usersdata;

  let idCounter = 1; // Initialize the counter

  // Assign a unique sequential ID to each row
  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++, // Assign the current counter value and then increment it
    fullname: row.firstname + " " + row.lastname,
  }));
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (isMainLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const handleUpdateStockist = (data, setSubmitting, resetForm) => {
    const additionalData = {
      id: stockistData.userid,
      country: data.country.id,
      city: data.city.id,
    };
    const newData = { ...data, ...additionalData };

    updatestockistsdata(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
          setAddStockistModalOpen(false);
          setUpdateStockistModalOpen(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTimeout(() => {
            setSubmitting(false);
          }, 2000);
          UsersData();
          setAddStockistModalOpen(false);
          setUpdateStockistModalOpen(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handleUpdateStockistStatus = () => {
    setBtnLoading(true);
    const params = {
      id: stockistData?.userid,
    };
    postRequest(
      "/updatestockiststatus",
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setModalOpen(false);
          setBtnLoading(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          UsersData();
          setUpdateStockistModalOpen(false);
          setStatusStockistModalOpen(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  // useEffect(() => {
  //   fetchCountriesList();
  // }, []);

  return (
    <JumboDemoCard
      title={"Seller"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container>
          <Grid item sm={10} sx={style}>
            <Typography id="modal-modal-title" variant="h3" component="h2">
              Are you sure you want to{" "}
              <b>{rowData?.loginstatus === "block" ? "Unblock" : "Block"}</b>{" "}
              the <b>{rowData?.username}</b>
            </Typography>
            <List disablePadding sx={{ mb: 2, mt: 5, textAlign: "center" }}>
              <LoadingButton
                variant="contained"
                loading={btnLoading}
                onClick={handleUpdateAccount}
                color={rowData?.loginstatus === "block" ? "warning" : "info"}
              >
                Yes
              </LoadingButton>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                sx={{ ml: 2 }}
              >
                No
              </Button>
            </List>
          </Grid>
        </Grid>
      </Modal>

      <Box sx={{ height: 500, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[15, 30, 75, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>

      <Modal
        open={addStockistModalOpen}
        onClose={handleCloseAddStockist}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Add Stockists
          </Typography>
          <List disablePadding sx={{ mb: 2, mt: 5 }}>
            <Formik
              validateOnChange={true}
              initialValues={{
                storename: stockistData?.storename || "",
                mobile: stockistData?.mobile || "",
                address: stockistData?.address || "",
                city: stockistData?.city || "",
                country: stockistData?.country || "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                handleUpdateStockist(data, setSubmitting, resetForm);
                handleClose();
              }}
            >
              {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="storename"
                      label="Store Name"
                      type="text"
                      inputPropes={{ readOnly: true }}
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="mobile"
                      label="Phone"
                      type="number"
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <JumboTextField
                      fullWidth
                      name="address"
                      label="Address"
                      type="text"
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.country}
                      onChange={(event, newValue) => {
                        setFieldValue("country", newValue || null);
                        postRequest(
                          "/getcities",
                          { countryid: newValue?.id },
                          (response) => {
                            if (response?.data?.status === "success") {
                              setCitiesList(response?.data?.data);
                            }
                          }
                        );
                      }}
                      id="vehmake-select"
                      options={countrieslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      // renderOption={(props, option) => (
                      //   <Box
                      //     component="li"
                      //     sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      //     {...props}
                      //   >
                      //     <img
                      //       loading="lazy"
                      //       width="20"
                      //       src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      //       srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      //       alt=""
                      //     />
                      //     {option.name} ({option.iso2}) +{option.phonecode}
                      //   </Box>
                      // )}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="country"
                          label="Select Country"
                          variant="outlined"
                          error={touched.country && errors.country}
                          helperText={touched.country && errors.country}
                        />
                      )}
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                    <Autocomplete
                      sx={{ width: "100%" }}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setFieldValue("city", newValue);
                      }}
                      id="vehmake-select"
                      options={citieslList}
                      autoHighlight
                      getOptionLabel={(option) => option.name} // Display the title in the dropdown
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      } // Match by code
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="city"
                          label="Select city"
                          variant="outlined"
                          error={touched.city && errors.city}
                          helperText={touched.city && errors.city}
                        />
                      )}
                    />
                  </Div>
                  <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Div>
                </Form>
              )}
            </Formik>
          </List>
        </Div>
      </Modal>

      <Modal
        open={updateStockistModalOpen}
        onClose={handleCloseUpdateStockist}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Update Stockists
          </Typography>
          <List disablePadding sx={{ mb: 2, mt: 5 }}>
            <Alert severity="warning">
              This user is already a Stockist. You can do following action by
              click on button bellow.
            </Alert>
            <Grid container xs={12} sm={12}>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => setAddStockistModalOpen(true)}
                >
                  Update
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ mt: 2 }}
                  onClick={() => setStatusStockistModalOpen(true)}
                >
                  Disabled
                </Button>
              </Grid>
            </Grid>
          </List>
        </Div>
      </Modal>

      <Modal
        open={statusStockistModalOpen}
        onClose={handleCloseStatusStockist}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
          <Typography id="modal-modal-title" variant="h3" component="h2">
            Update Stockists
          </Typography>
          <List disablePadding sx={{ mb: 2, mt: 5 }}>
            <Typography variant="h6">
              Are you sure want to disabled the Stockist of this user
            </Typography>
            <Grid container xs={12} sm={12}>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleUpdateStockistStatus()}
                >
                  Yes
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setStatusStockistModalOpen(false);
                    setUpdateStockistModalOpen(false);
                  }}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </List>
        </Div>
      </Modal>
    </JumboDemoCard>
  );
};

export default Active;

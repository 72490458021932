import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { Button, Grid, Modal, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import ReactImageGallery from "react-image-gallery";

const VISIBLE_FIELDS = ["sr", "sender", "amount", "date"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const BuyPackageReport = () => {
  const [referralbonusdata, setReferralBonusData] = useState([]);
  const [imageurl, setImageUrl] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [imagesData, setImagesData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const modalOpen = () => setOpen(true);
  const modalClose = () => setOpen(false);

  const ReferralData = () => {
    postRequest(
      "/report",
      '',
      (response) => {
        console.log('response', response)
        if (response?.data?.status === "success") {
          setReferralBonusData(response?.data?.data);
          setImageUrl(response?.data?.imageURL);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    ReferralData();
  }, []);

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `$${params.value}`,
    },
    {
      field: "senderusername",
      headerName: "Seller",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "receiverusername",
      headerName: "Buyer",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "producttitle",
      headerName: "Product Title",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `Processing`, 
    },
    // {
    //   field: "type",
    //   headerName: "Type",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "picture",
      headerName: "Product Detail",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => handleOpen(params?.row?.id)}>
          View
        </Button>
      ),
    },
    {
      field: "createdat",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "details",
    //   headerName: "Details",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 200,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
  ];
  const rows = referralbonusdata;

  const handleOpen = (id) => {
    const rowToEdit = referralbonusdata.find((row) => row.id === id);
    if (rowToEdit) {
        setRowData(rowToEdit);
        const parsedData = JSON.parse(rowToEdit.images);
        const transformedData = parsedData.imageNames.map(imageName => ({
            original: imageurl + imageName,
            thumbnail: imageurl + imageName
        }));
        setImagesData(transformedData);
        modalOpen();
    } else {
        console.error("Row not found");
    }
};


  return (
    <JumboDemoCard
      title={"Purchased  Report"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <Modal
        open={open}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Div sx={style}>
        <Grid container>
              <Grid item xs={12} md={6}>
                <ReactImageGallery
                  showBullets={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={imagesData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Div>
                  <Typography variant="h2" sx={{ ml: 2, mt: 3 }}>
                    {rowData?.producttitle}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Seller Username: {rowData?.senderusername}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Full Name: {rowData?.firstname + " " + rowData?.lastname}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="body1" color="initial" sx={{ ml: 2 }}>
                    Email: {rowData?.email}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="h1" color="primary" sx={{ mt: 3, ml: 2 }}>
                    ${rowData?.amount}{" "}
                  </Typography>
                </Div>
                <Div>
                  <Typography variant="h5" color="initial" sx={{ mt: 3, ml: 2 }}>Detail:</Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>{rowData?.productdescription}</Typography>
                </Div>
              </Grid>
            </Grid>
        </Div>
      </Modal>
    </JumboDemoCard>
  );
};

export default BuyPackageReport;

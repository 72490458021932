import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getusers, postRequest } from "backendServices/ApiCalls";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CancelIcon from "@mui/icons-material/Cancel";

function PendingReports() {
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isMainLoading, setIsMainLoading] = useState(true);
  const [usersdata, setUsersData] = useState([]);
  const [userId, setUsersID] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  let params = {
    status: "pending",
    user_type: "Seller",
  };

  const generateRowId = () => {
    // Generate a random string or use any other logic to create a unique ID
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };

  const columns = [
    {
      field: "id",
      headerName: "Sr#",
      width: 50,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => params.row.id, // Use custom ID or generate one if it doesn't exist
    },
    {
      field: "username",
      headerName: "Username",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "fullname",
      headerName: "Full Name",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "email",
      headerName: "email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "country",
    //   headerName: "Country",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "user_type",
      headerName: "User Type",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "createdat",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Approve" placement="top">
            <IconButton
              onClick={() => handleActionClick(params.row.userid, "approved")}
              sx={{ color: "success.main" }}
            >
              <AddTaskIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reject" placement="top">
            <IconButton
              onClick={() => handleActionClick(params.row.userid, "rejected")}
              sx={{ color: "error.main" }}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const UsersData = () => {
    getusers(
      params,
      (response) => {
        console.log("userdata", response);
        if (response?.data?.status === "success") {
          setUsersData(response?.data?.userdata);
          setIsMainLoading(false);
        }
      },
      (error) => {
        console.log(error?.response?.data);
        setIsMainLoading(false);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const handleActionClick = (id, action) => {
    if (action === "approved") {
      setOpenApproveDialog(true);
      setUsersID(id);
    } else if (action === "rejected") {
      setOpenDialog(true);
      setUsersID(id);
    }
  };

  const handleApprove = () => {
    return new Promise((resolve) => {
      let params = {
        status: "approved",
        userid: userId,
      };
      postRequest(
        "/selleraction",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setOpenApproveDialog(false);
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            UsersData();
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve();
        }
      );
    });
  };

  const handleReject = () => {
    return new Promise((resolve) => {
      let params = {
        status: "rejected",
        userid: userId,
      };

      postRequest(
        "/selleraction",
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setOpenDialog(false);
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            UsersData();
          }
        },
        (error) => {
          console.log(error?.response?.data);
          resolve();
        }
      );
    });
  };

  const rows = usersdata;

  let idCounter = 1; // Initialize the counter

  // Assign a unique sequential ID to each row
  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++, // Assign the current counter value and then increment it
    fullname: row.firstname + " " + row.lastname,
  }));

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  if (isMainLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const handleCloseApproveDialog = () => {
    setSelectedUserId(null);
    setOpenApproveDialog(false);
  };

  const handleCloseDialog = () => {
    setSelectedUserId(null);
    setOpenDialog(false);
  };

  return (
    <JumboDemoCard
      title={"Pending Sellers"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Box sx={{ height: 500, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 15 } },
          }}
          rows={rowsWithId}
          getRowId={(row) => generateRowId()}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[15, 30, 75, 100]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reject this seller?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleCloseDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleReject}
            color="error"
            autoFocus
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openApproveDialog} onClose={handleCloseApproveDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to approve this seller
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={handleCloseApproveDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleApprove}
            color="success"
            autoFocus
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </JumboDemoCard>
  );
}

export default PendingReports;

import React from "react";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import LogoutIcon from "@mui/icons-material/Logout";

import PasswordIcon from "@mui/icons-material/Password";
import GroupIcon from "@mui/icons-material/Group";
import VideocamIcon from "@mui/icons-material/Videocam";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaidIcon from "@mui/icons-material/Paid";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import SettingsIcon from "@mui/icons-material/Settings";
import ReportIcon from "@mui/icons-material/Report";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BalanceIcon from "@mui/icons-material/Balance";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { ShoppingCartOutlined } from "@mui/icons-material";
import InventoryIcon from "@mui/icons-material/Inventory";
const menus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/dashboard",
        label: "sidebar.menuItem.dashboard",
        type: "nav-item",
        icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //     label: 'sidebar.menuItem.miniadmin',
      //     type: "collapsible",
      //     icon: <GroupIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/create-admin",
      //             label: 'sidebar.menuItem.createadmin',
      //             type: "nav-item",
      //             // icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />
      //         },
      //         {
      //             uri: "/manage-admin",
      //             label: 'sidebar.menuItem.manageadmin',
      //             type: "nav-item",
      //             // icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />
      //         },
      //     ]
      // },
      //     {
      //         label: 'sidebar.menuItem.orders',
      //         type: "collapsible",
      //         icon: <InventoryOutlinedIcon sx={{ fontSize: 20 }} />,
      //         children: [
      //             {
      //                 uri: "/pendingorder",
      //                 label: 'sidebar.menuItem.pendingorder',
      //                 type: "nav-item",
      //             },
      //             {
      //                 uri: "/approvedorder",
      //                 label: 'sidebar.menuItem.approvedorder',
      //                 type: "nav-item",
      //             },
      //         ]
      // },
      {
        label: "sidebar.menuItem.appUsers",
        type: "collapsible",
        icon: <GroupIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/buyer-users",
            label: "sidebar.menuItem.activeusers",
            type: "nav-item",
          },
          {
            uri: "/seller-users",
            label: "sidebar.menuItem.inactiveusers",
            type: "nav-item",
          },
        ],
      },
			{
        label: "sidebar.menuItem.sellerreport",
        type: "collapsible",
        icon: <GroupIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/pending-seller",
            label: "sidebar.menuItem.pendingseller",
            type: "nav-item",
          },
          {
            uri: "/approved-seller",
            label: "sidebar.menuItem.approvedseller",
            type: "nav-item",
          },
					{
            uri: "/rejected-seller",
            label: "sidebar.menuItem.rejectedseller",
            type: "nav-item",
          },
        ],
      },
      {
          label: 'sidebar.menuItem.products',
          type: "collapsible",
          icon: <ShoppingCartOutlined sx={{ fontSize: 20 }} />,
          children: [
              {
                  uri: "/products",
                  label: 'All',
                  type: "nav-item",
                  icon: <Diversity1Icon sx={{ fontSize: 20 }} />
              },
              {
                  uri: "/manage-products",
                  label: 'Pending',
                  type: "nav-item",
                  icon: <Diversity1Icon sx={{ fontSize: 20 }} />
              },
              {
                uri: "/approved-products",
                label: 'Approved',
                type: "nav-item",
                icon: <Diversity1Icon sx={{ fontSize: 20 }} />
            },
            {
              uri: "/rejected-products",
              label: 'Rejected',
              type: "nav-item",
              icon: <Diversity1Icon sx={{ fontSize: 20 }} />
          },
          ]
      },
      // {
      //     label: 'Deposit Report',
      //     type: "collapsible",
      //     icon: <PaidIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/pending-deposit",
      //             label: 'Pending',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/approved-deposit",
      //             label: 'Approved',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/rejected-deposit",
      //             label: 'Rejected',
      //             type: "nav-item",
      //         },
      //     ]
      // },
      // {
      //     label: 'Packages Report',
      //     type: "collapsible",
      //     icon: <PaidIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/pending-packages",
      //             label: 'Pending',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/approved-packages",
      //             label: 'Approved',
      //             type: "nav-item",
      //         }
      //     ]
      // },
      // {
      //     uri: "/order-history",
      //     label: 'Order History',
      //     type: "nav-item",
      //     icon: <GraphicEqIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     label: 'Rank Achivers',
      //     type: "collapsible",
      //     icon: <PaidIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/pending-achievers",
      //             label: 'Pending',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/approved-achievers",
      //             label: 'Approved',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/expired-achievers",
      //             label: 'Expired',
      //             type: "nav-item",
      //         },
      //     ]
      // },
      // {
      //   label: "sidebar.menuItem.payoutdetail",
      //   type: "collapsible",
      //   icon: <PaidIcon sx={{ fontSize: 20 }} />,
      //   children: [
      //     {
      //       uri: "/pending-payout",
      //       label: "sidebar.menuItem.payoutdetailpending",
      //       type: "nav-item",
      //     },
      //     {
      //       uri: "/approved-payout",
      //       label: "sidebar.menuItem.payoutdetailapproved",
      //       type: "nav-item",
      //     },
      //     {
      //       uri: "/rejected-payout",
      //       label: "sidebar.menuItem.payoutdetailrejected",
      //       type: "nav-item",
      //     },
      //   ],
      // },
      // {
      //   label: "Packages Report",
      //   type: "collapsible",
      //   icon: <InventoryIcon sx={{ fontSize: 20 }} />,
      //   children: [
      //     {
      //       uri: "/pending-packages",
      //       label: "Pending Packages",
      //       type: "nav-item",
      //     },
      //     {
      //       uri: "/approved-packages",
      //       label: "Approved Packages",
      //       type: "nav-item",
      //     },
      //     {
      //       uri: "/rejected-packages",
      //       label: "Rejected Packages",
      //       type: "nav-item",
      //     },
      //   ],
      // },
      {
        label: "sidebar.menuItem.reports",
        type: "collapsible",
        icon: <ReportIcon sx={{ fontSize: 20 }} />,
        children: [
          // {
          //   uri: "/binary-points-report",
          //   label: "sidebar.menuItem.binarypoints",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/binary-bonus",
          //   label: "Binary Bonus",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/referral-bonus",
          //   label: "Referral Bonus",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/rank",
          //   label: "Rank Report",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/unilevel-bonus",
          //   label: "sidebar.menuItem.unilevelbonus",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //     uri: "/stockist-bonus",
          //     label: 'Stockist Bonus',
          //     type: "nav-item",
          //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
          // },
          // {
          //   uri: "/pairing-bonus",
          //   label: "Pairing Bonus",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //     uri: "/pool-bonus",
          //     label: 'Pool Bonus',
          //     type: "nav-item",
          //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
          // },
          // {
          //   uri: "/roi-summary",
          //   label: "Roi Summary",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/rank-bonus",
          //   label: "Rank Bonus",
          //   type: "nav-item",
          //   icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          // },
          {
            uri: "/buy-product-report",
            label: "Purchased  Report",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
        ],
      },

      {
        label: "sidebar.menuItem.settings",
        type: "collapsible",
        icon: <SettingsIcon sx={{ fontSize: 20 }} />,
        children: [
          // {
          //   uri: "/add-deposit-wallet",
          //   label: "Deposit Accounts",
          //   type: "nav-item",
          //   icon: <BalanceIcon sx={{ fontSize: 20 }} />,
          // },
          // {
          //     uri: "/updateuserbalance",
          //     label: 'sidebar.menuItem.updateuserbalance',
          //     type: "nav-item",
          //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
          // },
          // {
          //     uri: "/manage-packages",
          //     label: 'sidebar.menuItem.managepackages',
          //     type: "nav-item",
          //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
          // },
          // {
          //     uri: "/manage-ranks",
          //     label: 'sidebar.menuItem.manageranks',
          //     type: "nav-item",
          //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
          // },
          // {
          //     uri: "/manage-city",
          //     label: 'sidebar.menuItem.managecity',
          //     type: "nav-item",
          //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
          // },
          // {
          //     uri: "/managetransactionpassword",
          //     label: 'sidebar.menuItem.managetransactionpassword',
          //     type: "nav-item",
          //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
          // },
          // {
          //   uri: "/updatewithdrawal",
          //   label: "sidebar.menuItem.updatewithdrawal",
          //   type: "nav-item",
          //   icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/managecommission",
          //   label: "sidebar.menuItem.managecommission",
          //   type: "nav-item",
          //   icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
          // },
          // {
          //   uri: "/manage-unilevel",
          //   label: "sidebar.menuItem.manageunilevel",
          //   type: "nav-item",
          //   icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />,
          // },
          // {
          //     uri: "/manage-shipping",
          //     label: 'Shipping Charges',
          //     type: "nav-item",
          //     icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />
          // },
          // {
          //   uri: "/managezoom",
          //   label: "sidebar.menuItem.managezoom",
          //   type: "nav-item",
          //   icon: <VideocamIcon sx={{ fontSize: 20 }} />,
          // },
          // {
          //     uri: "/manageunilevelbonus",
          //     label: 'sidebar.menuItem.manageunilevelbonus',
          //     type: "nav-item",
          //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
          // },
          // {
          //     uri: "/updateloginpassword",
          //     label: 'sidebar.menuItem.updateloginpassword',
          //     type: "nav-item",
          //     icon: <PasswordIcon sx={{ fontSize: 20 }} />
          // },
          {
            uri: "/updatetransactionpassword",
            label: "sidebar.menuItem.updatetransactionpassword",
            type: "nav-item",
            icon: <PasswordIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/updateuserpassword",
            label: "sidebar.menuItem.updateuserpassword",
            type: "nav-item",
            icon: <PasswordIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
      // {
      //     label: 'sidebar.menuItem.kyc',
      //     type: "collapsible",
      //     icon: <SupportAgentIcon sx={{ fontSize: 20 }} />,
      //     children: [
      //         {
      //             uri: "/kycpending",
      //             label: 'sidebar.menuItem.kycpending',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/approvedkyc",
      //             label: 'sidebar.menuItem.approvedkyc',
      //             type: "nav-item",
      //         },
      //         {
      //             uri: "/rejectedkyc",
      //             label: 'sidebar.menuItem.rejectedkyc',
      //             type: "nav-item",
      //         },
      //     ]
      // },

      {
        label: "sidebar.menuItem.news",
        type: "collapsible",
        icon: <NewspaperIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/addnews",
            label: "sidebar.menuItem.addnews",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/managenews",
            label: "sidebar.menuItem.managenews",
            type: "nav-item",
            icon: <Diversity1Icon sx={{ fontSize: 20 }} />,
          },
        ],
      },

      {
        uri: "/profile",
        label: "sidebar.menuItem.updateprofile",
        type: "nav-item",
        icon: <PasswordIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/logout",
        label: "sidebar.menuItem.logout",
        type: "nav-item",
        icon: <LogoutIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
